<template>
    <div :style="`width: ${clientWidth}px;height: ${clientHeight}px`" id="apply2">
        <div v-if="info.state == 10" class="shengming">
            <div class="title">声明书</div>
            <div style="margin-top: 10px;color: #666666;line-height: 22px;">兹确认已缴纳蜜蛋网保证金<span class="xiahuaxian">{{info.payMoney}}</span>元，相关收据缺失。现申请退还保证金<span class="xiahuaxian">{{info.returnMoney}}</span>元，退款至本人蜜蛋网美妆账户余额，退款后本人已缴保证金为<span class="xiahuaxian">{{info.currentMoney}}</span>元。</div>
            <div @click="agree = !agree" class="confirm_box">
                <div v-if="!agree" class="cancel_circle"></div>
                <img v-else style="width: 16px;height: 16px;margin-right: 10px;" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210826165402107.png" alt="">
                <div>阅读并同意《声明书》</div>
            </div>
        </div>

        <div style="
                    width: 315px;
                    height: 33px;
                    background-color: #FF98A0;
                    border-radius: 500px;
                    line-height: 35px;
                    color: #fff;
                    text-align: center;
                    position: absolute;
                    bottom: 60px;
                    left: 50%;
                    transform: translateX(-50%);"
             v-if="info.state == 10" @click="submit">
            提交申请
        </div>
        <div style="
                    width: 315px;
                    height: 33px;
                    background-color: #FF98A0;
                    border-radius: 500px;
                    line-height: 35px;
                    color: #fff;
                    text-align: center;
                    position: absolute;
                    bottom: 60px;
                    left: 50%;
                    transform: translateX(-50%);"
             v-if="info.state == 30" @click="applyAgain">
            重新申请
        </div>
        <div style="
                    width: 315px;
                    height: 33px;
                    background-color: #fff;
                    border-radius: 500px;
                    line-height: 35px;
                    color: #000;
                    text-align: center;
                    position: absolute;
                    bottom: 60px;
                    left: 50%;
                    transform: translateX(-50%);
                    box-shadow: 0 0 5px rgba(0,0,0,.1)"
             v-if="info.state == 20" @click="goMyCenter">
            返回个人中心
        </div>
        <applySuccess v-if="info.state == 40" :info="info"/>
        <failed v-if="info.state == 30" :info="info" />
        <under-review v-if="info.state == 20" :info="info" />
    </div>
</template>

<script>
    import underReview from "./underReview";
    import applySuccess from "./applySuccess";
    import failed from "./failed";
    export default {
        name: "apply2",
        components: {applySuccess, failed, underReview},
        data() {
            return {
                clientHeight: '',
                clientWidth: '',
                brandId: '',
                info: '',
                agree: false
            }
        },
        created() {
            document.title = '收据缺失申请'
            this.clientHeight = document.documentElement.clientHeight
            this.clientWidth = document.documentElement.clientWidth
            this.brandId = this.$route.params.brandId
            this.GetApplyChangeDetails()
        },
        methods: {
            GetApplyChangeDetails() {
                this.post('/PersonCenter/TeamAgent/GetApplyChangeDetails', {brandId: this.brandId}, true, 2).then(data => {
                    if (data.code != 1) {
                        return this.$dialog.confirm({
                            title: '提示',
                            message: data.msg
                        })
                            .then(e => {
                                this.$router.back()
                            })
                            .catch(e => {
                                this.$router.back()
                            })
                    }
                    this.info = data.response
                })
            },
            goMyCenter() {
                window.location.href = this.baseHost + '/7999/Arongleweb/html/mycenter/manage.html'
            },
            submit() {
                if (!this.agree) return this.$toast('请您勾选《声明书》！')
                this.$dialog.confirm({
                    title: '提示',
                    message: '确定提交申请吗？'
                }).then(() => {
                    this.$toast.loading({duration: 0})
                    this.post('/PersonCenter/TeamAgent/UpdateApplyChange', {
                        id: this.info.id,
                        imgurl: '',
                        isReceipt: 2
                    }, true, 2).then(data => {
                        this.$toast.clear()
                        if (data.code == 1) {
                            this.info.state = 20
                        } else {
                            this.$dialog.confirm({
                                title: '提示',
                                message: data.msg
                            })
                        }
                    })
                })
                .catch(() => {})
            },
            applyAgain() {
                this.$toast.loading({duration: 0})
                this.post('/PersonCenter/TeamAgent/UpdateApplyChangeAgain', {id: this.info.id}, true, 2).then(data => {
                    this.$toast.clear()
                    if (data.code == 1) {
                        this.$router.push(`/RefundDifference/${this.brandId}`)
                    } else {
                        this.$dialog.confirm({
                            title: '提示',
                            message: data.msg
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    #apply2 {
        background-color: #F8F8F8;
        text-align: left;
        .xiahuaxian {
            display: inline-block;
            min-width: 30px;
            text-align: center;
            border-bottom: 1px solid #333;
        }
        .shengming {
            background-color: #fff;
            box-sizing: border-box;
            padding: 20px 15px;

            .title {
                font-size: 15px;
                font-weight: bold;
            }

            .confirm_box {
                display: flex;
                margin-top: 40px;
                align-items: center;
                color: #999999;

                .cancel_circle {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    box-sizing: border-box;
                    border: 1px solid #AAAAAA;
                    margin-right: 10px;
                }
            }
        }
    }
</style>